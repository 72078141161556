<template>
  <div>
    <b-form ref="form" class="repeater-form" @submit.prevent="repeatAgain">
      <!-- Row Loop -->
      <b-row v-for="(currentValue, index) in value" :id="currentValue.id" :key="currentValue.id" ref="row" class="d-flex justify-start">
        <b-col sm="3">
          <app-drop-zone
            :id="`attributeValueIconId${index}`"
            :ref="`attributeValueIcon${index}`"
            :value="currentValue"
            file-url-key="iconFileUrl"
            class="d-flex justify-content-center align-content-center dropzone_small"
            :options="dropzoneOptionsIcon"
            @vdropzone-removed-file="removeFile(value[index].iconFileUrl, value[index].iconFilePath, value[index], 'deleteOldIcon')"
            @vdropzone-file-added="sendingFile($event, value[index], 'iconId', 'attributeValueIconUploaded', 'deleteOldIcon', null, `attributeValueIcon${index}`)"
          />
        </b-col>
        <!-- Item Name -->
        <b-col md="12" offset-md="0" offset-sm="1" sm="8">
          <b-row>
            <b-col>
              <app-form-text-input-itn
                v-model="currentValue.name"
                :label="$t('attribute.input_name')"
                :languages="activelang"
                form-name="attribute-add-value"
              />
            </b-col>

            <b-col class="mt-auto mb-1" xl="3">
              <!-- Remove Button -->
              <b-button-group class="ml-2 mt-2">
                <b-button v-if="value.length !== 1" v-ripple.400 size="md" variant="outline-primary" @click="removeItem(index)">
                  <font-awesome-icon v-b-tooltip.hover.bottom.v-danger :title="$t('action.delete')" class="text-danger" icon="minus" />
                </b-button>
                <b-button v-if="index === value.length - 1" v-ripple.400 size="md" variant="outline-primary" @click="repeatAgain">
                  <font-awesome-icon v-b-tooltip.hover.bottom.v-danger :title="$t('action.add')" class="text-success" icon="plus" />
                </b-button>
              </b-button-group>
            </b-col>
            <b-col v-if="index !== value.length - 1" cols="12"><hr /></b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { heightTransition } from '@core/mixins/ui/transition'

import AppDropZone from '@/components/AppDropZone.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import imgUpload from '@/helpers/imgUpload'

export default {
  name: 'AttributeAddValues',
  components: {
    AppDropZone,
  },

  mixins: [heightTransition, imgUpload],
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
    translations: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      attributeValueIconUploaded: true,
      dropzoneOptionsIcon: {
        url: 'https://localhost',
        dictDefaultMessage: this.$t('attribute.value.icon.upload'),
        thumbnailWidth: 150,
        /* maxFilesize: 0.5, */
        maxFilesize: 2000000,
        headers: { 'My-Awesome-Header': 'header value' },
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        uploadMultiple: false,
        init() {
          this.hiddenFileInput.removeAttribute('multiple')
          this.on('addedfile', function () {
            if (this.files.length > 1) {
              this.removeFile(this.files[0])
            }
          })
        },
      },
    }
  },

  computed: {
    ...mapState('auth', ['activelang']),
  },

  watch: {
    attributeValueIconUploaded(val) {
      this.$emit('onAttributeValueIconUploaded', val)
    },
    value: {
      deep: true,
      handler(val) {
        this.$emit('onValues', val)
      },
    },
  },
  mounted() {
    this.setValues()
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    setValues() {
      const val = {
        name: {},
        iconId: null,
      }
      this.activelang.forEach(lang => {
        val.name[lang.locale] = ''
      })
      const updatedValues = this._cloneDeep(this.value)
      updatedValues.push(val)
      this.$emit('input', updatedValues)
    },
    repeatAgain() {
      const val = {
        name: {},
        iconId: null,
      }
      this.activelang.forEach(lang => {
        val.name[lang.locale] = ''
      })
      const updatedValues = this._cloneDeep(this.value)
      updatedValues.push(val)
      this.$emit('input', updatedValues)
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      const updatedValues = this._cloneDeep(this.value)
      updatedValues.splice(index, 1)
      this.$emit('input', updatedValues)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
.dropzone_small.vue-dropzone.dropzone {
  padding: 0;
  width: 160px;
  height: 160px;
  .dz-preview {
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: inherit;
    min-width: inherit;
    .dz-image {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      margin: auto;
      img {
        object-fit: inherit;
      }
    }
    .dz-error-mark {
      margin: auto;
      text-align: center;
    }
    .dz-remove {
      right: 16px;
      margin: auto;
    }
  }
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.vue-dropzone.dropzone {
  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color 0.2s linear;
  height: 200px;
  padding: 40px;
}
.vue-dropzone.dropzone .dz-preview {
  width: 160px;
  display: inline-block;
}
.vue-dropzone.dropzone .dz-preview .dz-image {
  width: 133px;
  height: 133px;
  margin-left: 40px;
  margin-bottom: 10px;
}

.vue-dropzone.dropzone .dz-remove {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.vue-dropzone.dropzone .dz-preview {
  vertical-align: top;
  min-height: 145px;
  min-width: 230px;
  margin: 5px 0 0;
}
.vue-dropzone.dropzone .dz-preview .dz-error-message {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  padding: 3px;
  /* margin-top: 8px; */
  font-size: 12px;
  border-radius: 0;
  top: 0;
  background: #ea5455;
  margin-top: 0;
}
.vue-dropzone.dropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-size: contain;
}
.vue-dropzone.dropzone {
  padding: 13px;
}
.vue-dropzone.dropzone .dz-details {
  background-color: rgb(220 193 129);
}
.vue-dropzone > .dz-preview .dz-details {
  background-color: rgb(220 193 129);
}
.vue-dropzone.dropzone .dz-preview .dz-image > img {
  width: 100%;
}
.vue-dropzone.dropzone .dz-preview .dz-details {
  color: white;
  transition: opacity 0.2s linear;
  text-align: center;
}
</style>
