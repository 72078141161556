<template>
  <b-card>
    <validation-observer ref="mainAttributeAddRules" tag="form">
      <b-form>
        <b-row class="mb-1 match-height">
          <b-col cols="12" md="6">
            <b-card class="border rounded">
              <b-card-title class="text-center">
                {{ $t('attribute.sidebar.title') }}
              </b-card-title>
              <section class="mb-2">
                <app-form-text-input-itn
                  v-model="attribute.name"
                  :label="$t('attribute.name')"
                  :languages="activelang"
                  form-name="attribute-add"
                />

                <b-form-checkbox v-model="attribute.filterable" :checked="attribute.filterable" name="check-button" switch>
                  {{ $t('attribute.filterable') }}
                </b-form-checkbox>

                <b-form-checkbox v-model="attribute.required" :checked="attribute.required" name="check-button" switch>
                  {{ $t('attribute.required') }}
                </b-form-checkbox>

                <enum-select id="type" v-model="attribute.type" :clearable="false" :label="$t('attribute.type')" enum-class="AttributeType" required />
              </section>
              <hr />
              <section class="mt-2">
                <b-card>
                  <div class="d-flex align-items-center">
                    <b-form-group :label="$t('attribute.attached_categories')" class="mr-50 validation-required" label-for="categories_attached" />
                    <div class="ml-auto">
                      <b-button size="sm" variant="outline-primary" @click="checkAll = !checkAll">
                        <font-awesome-icon :icon="checkAll ? 'minus' : 'plus'" class="mr-50" />
                        {{ checkAll ? $t('action.uncheck_all') : $t('action.check_all') }}
                      </b-button>
                    </div>
                  </div>
                  <div class="border-primary rounded cat-list">
                    <categories-tree
                      :check-all.sync="checkAll"
                      :current-parent="attribute.categories.map(cat => cat.id)"
                      multiselect
                      @category-selected="onSetCategorySelected"
                    />
                  </div>
                </b-card>
              </section>
            </b-card>
          </b-col>
          <!--VALUES-->
          <b-col md="6">
            <b-card class="border rounded wrapper_attribute-value">
              <b-card-title class="text-center">
                {{ $t('attribute.values') }}
              </b-card-title>
              <AttributeValues
                v-model="attribute.values"
                :translations="translations"
                @onAttributeValueIconUploaded="val => (saveAvailable = val)"
                @onValues="val => (attribute.values = val)"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-row class="mr-0 p-1 justify-content-end">
      <b-button :to="{ name: 'attributes' }" class="mr-50" type="button" variant="outline-secondary">
        <font-awesome-icon class="mr-50" icon="ban" />
        {{ $t('action.cancel') }}
      </b-button>
      <b-button class="d-flex" @click="saveAvailable ? CheckForm() : toaster($t('alert.wait_files_processing'))">
        <b-spinner v-if="!saveAvailable" class="mr-50" small />
        <font-awesome-icon v-else class="mr-50" icon="save" />
        {{ $t('action.save_and_quit') }}
      </b-button>
    </b-row>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { omit } from 'lodash'

import CategoriesTree from '@/views/category/CategoriesTree.vue'
import AttributeValues from '@/views/attribute/AttributeAddValues.vue'

import { postAttributeRequest } from '@/request/globalApi/requests/attributeRequests'

export default {
  name: 'AttributeAdd',

  components: {
    AttributeValues,
    CategoriesTree,
  },
  data() {
    return {
      saveAvailable: true,
      checkAll: false,
      attribute: {
        name: {},
        filterable: false,
        required: false,
        type: 'input',
        categories: [],
        values: [],
      },
      translations: null,
    }
  },

  computed: {
    ...mapState('appConfig', ['lang']),
    ...mapState('auth', ['activelang']),
  },

  created() {
    this.activelang.forEach(lang => {
      this.attribute.name[lang.locale] = ''
    })
  },

  methods: {
    CheckForm() {
      return this.$refs.mainAttributeAddRules.validate().then(success => {
        if (success) {
          if (!this.attribute.categories.length) {
            this.toaster(this.$t('alert.select_at_least_one_category'), 'danger', 'EditIcon')
          } else {
            this.attribute.values.map(val => ({
              ...val,
              name: omit(val.name, 'currentLanguage'),
            }))
            postAttributeRequest(this.attribute, this.attribute.name[this.lang]).then(() => {
              this.$router.push({ name: 'attributes' })
            })
          }
        } else {
          this.toaster(this.$t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
        }
      })
    },
    onSetCategorySelected(cats) {
      this.attribute.categories = cats.map(cat => cat.id)
    },
  },
}
</script>

<style>
.wrapper_attribute-value {
  max-height: 690px;
  overflow: auto;
}
.cat-list {
  max-height: 255px;
  overflow: auto;
}
</style>
